import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer, useFormatter, useRelationshipInjector } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/trace-km';
const model = 'cars/traceKm';
const modelExposedName = 'Traces';
const drawerRouteBase = 'tracekms';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, activeModules } = useContext(AppContext);
    const { injectToDetail } = useRelationshipInjector();
    const { formatDate, formatDistance } = useFormatter()

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    
    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (activeModules === undefined) return;
        if (typeof activeModules[dataProviderName] === 'undefined') return;
        // setDetailConfig(activeModules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "create_new_trace_km",
                    "fieldsets": [
                        {
                            "name": "vehicle_information",
                            "fields": {
                                "car": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "cars"
                                    },
                                    "view": "car"
                                },
                                "legalEntity": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "filter": {
                                            "items": [
                                                {
                                                    "field": "type",
                                                    "operator": "equals",
                                                    "value": "customer"
                                                }
                                            ]
                                        }
                                    },
                                    "view": "legalEntity"
                                },
                            }
                        },
                        {
                            "name": "trace_data",
                            "fields": {
                                "value": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 1"
                                },
                                "traced_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 1"
                                },
                                "value2": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 2"
                                },
                                "traced2_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 2"
                                },
                                "value3": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 3"
                                },
                                "traced3_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 3"
                                },
                                "value4": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 4"
                                },
                                "traced4_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 4"
                                },
                                "value5": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 5"
                                },
                                "traced5_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 5"
                                },
                                "value6": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 6"
                                },
                                "traced6_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 6"
                                },
                                "value7": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 7"
                                },
                                "traced7_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 7"
                                },
                                "value8": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 8"
                                },
                                "traced8_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 8"
                                },
                                "value9": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 9"
                                },
                                "traced9_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 9"
                                },
                                "value10": {
                                    "type": "number",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faRoad",
                                    "label": "value 10"
                                },
                                "traced10_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar",
                                    "label": "tracing date 10"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });
        setModelName(activeModules[dataProviderName].modelName);
    }, [activeModules, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded) {
            loaded = true;
            loadOne(params.filters?._id);
        }
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success]);

    injectToDetail(item?.data, 'car', params.car);
    injectToDetail(item?.data, 'legalEntity', params.legalEntity);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
        close();
        loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={
                        params.traceKmsLast ?
                        t('Fill the basic information to create a new {{itemname}}. Last trace ({{lasttracevalue}}) has been recorded at {{lasttracedate}}', {itemname:t(modelExposedName), lasttracevalue: formatDistance(params.traceKmsLast.value), lasttracedate: formatDate(params.traceKmsLast.traced_at)})
                        :
                        t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})
                    }
                />
            }
            {...params}
        />
    </>
}

export default Create;


