//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';

import * as Modules from './mtska-frontend-modules';
import { ImportModule } from './mtska-frontend-modules/modules/Import';

const AppExecutive = () => {

    const activemodules = process.env.REACT_APP_MODULES.split(',').map((module) => Modules[module]).filter((item) => { return item !== undefined});
    
    const config = {
        logo: process.env.REACT_APP_LOGO,
        title: process.env.REACT_APP_SHORT_NAME,
        modules: [
            ImportModule,
            ...activemodules
        ],
        copyright:  process.env.REACT_APP_COPYRIGHT,
        privacy: {
            label: process.env.REACT_APP_PRIVACY_LABEL,
            url: process.env.REACT_APP_PRIVACY_URL
        },
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENTID,
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_AUTH0_AUTHORIZATIONPARAMS_REDIRECT_URI,
            }
        },
        colors: () => {
            let colors = {};
            colors['--app-main-dark'] = process.env.REACT_APP_MAIN_COLOR_DARK;
            colors['--app-main-shadowed'] = process.env.REACT_APP_MAIN_COLOR_SHADOWED;
            colors['--app-main'] = process.env.REACT_APP_MAIN_COLOR;
            colors['--app-main-subdued'] = process.env.REACT_APP_MAIN_COLOR_SUBDUED;
            colors['--app-main-dimmed'] = process.env.REACT_APP_MAIN_COLOR_DIMMED;
            colors['--app-main-light'] = process.env.REACT_APP_MAIN_COLOR_LIGHT;
            colors['--app-main-lightest'] = process.env.REACT_APP_MAIN_COLOR_LIGHTEST;
            
            colors['--app-secondary-dark'] = process.env.REACT_APP_SECONDARY_COLOR_DARK;
            colors['--app-secondary-shadowed'] = process.env.REACT_APP_SECONDARY_COLOR_SHADOWED;
            colors['--app-secondary'] = process.env.REACT_APP_SECONDARY_COLOR;
            colors['--app-secondary-subdued'] = process.env.REACT_APP_SECONDARY_COLOR_SUBDUED;
            colors['--app-secondary-dimmed'] = process.env.REACT_APP_SECONDARY_COLOR_DIMMED;
            colors['--app-secondary-light'] = process.env.REACT_APP_SECONDARY_COLOR_LIGHT;
            colors['--app-secondary-lightest'] = process.env.REACT_APP_SECONDARY_COLOR_LIGHTEST;

            colors['--app-surface-dark'] = process.env.REACT_APP_SURFACE_COLOR_DARK;
            colors['--app-surface-shadowed'] = process.env.REACT_APP_SURFACE_COLOR_SHADOWED;
            colors['--app-surface'] = process.env.REACT_APP_SURFACE_COLOR;
            colors['--app-surface-subdued'] = process.env.REACT_APP_SURFACE_COLOR_SUBDUED;
            colors['--app-surface-dimmed'] = process.env.REACT_APP_SURFACE_COLOR_DIMMED;
            colors['--app-surface-light'] = process.env.REACT_APP_SURFACE_COLOR_LIGHT;
            colors['--app-surface-lightest'] = process.env.REACT_APP_SURFACE_COLOR_LIGHTEST;

            colors['--app-menu-app-title'] = process.env.REACT_APP_MENU_APP_TITLE
            colors['--app-menu-background'] = process.env.REACT_APP_MENU_BACKGROUND;
            colors['--app-menu-background-item'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM;
            colors['--app-menu-background-item-text'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM_TEXT;
            colors['--app-menu-background-item-hover'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM_HOVER;
            colors['--app-menu-background-item-hover-text'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM_HOVER_TEXT;
            colors['--app-menu-background-item-hightlight'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM_HIGHLIGHT;
            colors['--app-menu-background-item-hightlight-text'] = process.env.REACT_APP_MENU_BACKGROUND_ITEM_HIGHLIGHT_TEXT;
            colors['--app-menu-background-flat-text'] = process.env.REACT_APP_MENU_FLAT_TEXT;
            colors['--app-menu-background-flat-text-hover'] = process.env.REACT_APP_MENU_FLAT_TEXT_HOVER;

            colors['--app-icon-primary'] = process.env.REACT_APP_ICON_PRIMARY;
            colors['--app-icon-secondary'] = process.env.REACT_APP_ICON_SECONDARY;
            
            return colors;
        }
    }

    return (
        <App concreteconfig={config} />
    )
}

export default AppExecutive;
