import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/joy";
import { redirect, useNavigate } from 'react-router-dom';

import { useDataProvider } from "../../../../../mtska-frontend-data-provider";

const defaultAuthenticatedUrl = process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URL;


const Auth0LoginButton = ({ className }) => {
    const { error, getAccessTokenWithPopup } = useAuth0();

    const navigate = useNavigate();

    const { loadOne: loadConfig } = useDataProvider('configs/config')
    const { loadAll: loadSettings} = useDataProvider('settings/setting')

    const { item, loadOne } = useDataProvider('auth/token')
    useEffect(() => {
        if (typeof item?.token === 'undefined') return;
        sessionStorage.setItem('token', item?.token)
    }, [item, navigate])

    const login = () => {
        (async () => {
            try {
                const t = await getAccessTokenWithPopup({
                    authorizationParams: {
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE
                    }
                })
                if (t !== null) {
                    sessionStorage.setItem('token', t)
                    await loadOne(t);
                    await loadConfig(t);
                    await loadSettings(t);

                    navigate(defaultAuthenticatedUrl, { replace: true });
                }
            } catch (e) { }

        })()

    }


    return <>
        {error && error.messages}

        <Button
            size="regular"
            variant="solid"
            className={className}
            onClick={login}>Accedi</Button>
    </>
}

export default Auth0LoginButton;