// import _ from "lodash";

const createConstants = (dataProviderName) => {

    return {
        SAVE_REQUEST:   'SAVE_REQUEST',
        SAVE_SUCCESS:   'SAVE_SUCCESS',
        SAVE_FAILURE:   'SAVE_FAILURE',
        GETALL_REQUEST: 'GETALL_REQUEST',
        GETALL_SUCCESS: 'GETALL_SUCCESS',
        GETALL_FAILURE: 'GETALL_FAILURE',
        GETONE_REQUEST: 'GETONE_REQUEST',
        GETONE_SUCCESS: 'GETONE_SUCCESS',
        GETONE_FAILURE: 'GETONE_FAILURE',
        DELETE_REQUEST: 'DELETE_REQUEST',
        DELETE_SUCCESS: 'DELETE_SUCCESS',
        DELETE_FAILURE: 'DELETE_FAILURE',
        RESET_REQUEST:  'RESET_REQUEST',
        RESET_SUCCESS:  'RESET_SUCCESS',
        RESET_FAILURE:  'RESET_FAILURE',

        DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
        DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
        DOWNLOAD_FAILURE: 'DOWNLOAD_FAILURE',
        

    }

  
}

export default createConstants
