import * as Views from "./views";
import { NavigationList } from "../NavigationList"

const Module = {
    name: "dashboard",
    defaultRoutePath: "/dashboard",
    menuItem: {
        ...NavigationList.dashboard.dashboard
    },

    internalMenuItems: {
        activities: NavigationList.dashboard,
        wizards: []
    },
    routes: [
        {
            path: "/dashboard",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
            ],
        },
    ],
    drawerRoutes: [     
    ],
}

export default Module;
