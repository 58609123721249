import React, { useState, useEffect } from "react"

import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, FormLabel, Autocomplete, AutocompleteOption, Select, Option } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useFormatter, useInfocarDataMapper } from "../../utils";
import InputTextControl from "./InputTextControl";

const WidgetMakeModelSetup = ({ editing, required, autoFocus, placeholder, hint, haserror, disabled, readonly, className = "", prefix = ""}) => {
    const { t } = useTranslation();
    const { getGearbox, getEnvironmentalClass, getBodytype, getDrivetrain, getFueltype } = useInfocarDataMapper();
    const { formatDateForInput } = useFormatter();

    if (!editing) readonly = true;
    const { control, setError, setValue, watch, getValues, resetField } = useFormContext();

    const { items: makes, loadAll: loadAllMakes, reset: resetAllMakes } = useDataProvider('infocar/makes');
    const [makeOptions, setMakeOptions] = useState([]);
    const { items: models, loadAll: loadAllModels, reset: resetAllModels } = useDataProvider('infocar/models');
    const [modelOptions, setModelOptions] = useState([]);
    const { items: setups, loadAll: loadAllSetups, reset: resetAllSetups } = useDataProvider('infocar/setups');
    const [setupOptions, setSetupOptions] = useState([]);
    const { item: technicalData, loadOne: loadOneTechnicalData, reset: resetAllTechnicalData } = useDataProvider('infocar/technicalData');
    const [isVolatile, setIsVolatile] = useState(true);
    const [source, setSource] = useState('infocar');

    useEffect(() => {
        loadAllMakes();

        return () => {
            resetAllMakes();
            resetAllModels();
            resetAllSetups();
            resetAllTechnicalData();
        }
    }, []);

    useEffect(() => {
        setIsVolatile(!(getValues().updated_at ));
    }, [getValues()])

    useEffect(() => {
        let source;
        if (!prefix) {
            source = getValues()['source'];
        } else {
            source = getValues()[prefix.slice(0, -1)]['source'];
        }
        if (typeof source == 'undefined'  || !source) source = 'infocar';
        setSource(source);
        
    }, [watch(prefix+'source')])

    useEffect(() => {
        if (Array.isArray(makes) && makes.length > 0) {
            setMakeOptions(makes.map((option, k) => {
                return { ...option, value: option.id }
            }));
        }
    }, [makes])

    const getModels = (obj) => {
        clearForm(true, true);
        if (obj) {
            loadAllModels({ make: obj.value });
        }
    }

    useEffect(() => {
        if (Array.isArray(models) && models.length > 0) {
            setModelOptions(models.map((option, k) => {
                return { ...option, value: option.id }
            }));
        }
    }, [models])

    const getSetups = (obj) => {
        clearForm(false, true);
        if (obj) {
            loadAllSetups({ model: obj.value });
        }
    }

    useEffect(() => {
        if (Array.isArray(setups) && setups.length > 0) {
            //console.debug('setups sono arrivate', setups);
            setSetupOptions(setups.map((option, k) => {
                return { ...option, value: option.id }
            }));
        }
    }, [setups])

    const getTechnicalData = (obj) => {
        clearForm(false, false);
        if (obj) {
            loadOneTechnicalData(obj.value);
            // alcuni dati della vettura sono presenti solo nel payload di descrizione del setup
            setValue(prefix+"source", 'infocar');
            setValue(prefix+"externalID", obj.value);
            setValue(prefix+"modelYear", obj.year);
            setValue(prefix+"startImmatriculation_at", formatDateForInput(obj.startImmatriculation_at));
            setValue(prefix+"endImmatriculation_at", formatDateForInput(obj.endImmatriculation_at));
        }
    }

    useEffect(() => {
        fillForm();
    }, [technicalData]);

    const fillForm = () => {
        if (!technicalData || !technicalData.DatiBase) {
            return;
        }

        //console.debug(' >>>> TECH DATA', technicalData);

        setValue(prefix+"bodyType", getBodytype(technicalData.DatiBase.Carrozzeria));
        setValue(prefix+"drivetrain", getDrivetrain(technicalData.MotorePrestazioni[0].Trazione));
        setValue(prefix+"gearbox", getGearbox(technicalData.MotorePrestazioni[0].Cambio));
        setValue(prefix+"fuelType", getFueltype([technicalData.DatiIbride?.TipoIbridazione, technicalData.MotorePrestazioni[0].Motore.Alimentazione, technicalData.MotorePrestazioni[1]?.Motore.Alimentazione]));
        setValue(prefix+"engineDisplacement", technicalData.MotorePrestazioni[0].Motore.Cilindrata);
        setValue(prefix+"seats", technicalData.DatiBase.NumeroPosti);
        setValue(prefix+"doors", technicalData.DatiBase.NumeroPorte);
        setValue(prefix+"environmentalClass", getEnvironmentalClass(technicalData.DatiBase.Normativa));
        setValue(prefix+"emissions", technicalData.MotorePrestazioni[0].Emissione3);
        setValue(prefix+"powerHp", technicalData.MotorePrestazioni[0].PotenzaCV);
        setValue(prefix+"powerKw", technicalData.MotorePrestazioni[0].PotenzaKW);
        setValue(prefix+"fuelTankCapacity", technicalData.MotorePrestazioni[0].CapacitaSerbatoioL);
        setValue(prefix+"fuelCombinedConsumption", technicalData.MotorePrestazioni[0].Consumo2);
        setValue(prefix+"fuelUrbanConsumption", technicalData.MotorePrestazioni[0].Consumo1);
        setValue(prefix+"fuelExtraUrbanConsumption", technicalData.MotorePrestazioni[0].Consumo3);
        setValue(prefix+"electricBatteryCapacity", null);
        setValue(prefix+"electricRangeKm", null);
        setValue(prefix+"electricCombinedConsumption", null);
        setValue(prefix+"electricUrbanConsumption", null);
        setValue(prefix+"electricExtraUrbanConsumption", null);
    }

    const clearForm = (resetModel, resetSetup) => {
        if (resetSetup) {
            resetField(prefix+"setup");
            setValue(prefix+"setup", '');
            clearLabel(prefix+"setup");
            setSetupOptions([]);
        }

        if (resetModel) {
            resetField(prefix+"model");
            setValue(prefix+"model", '');
            clearLabel(prefix+"model");
            setModelOptions([]);
        }
        
        setValue(prefix+"startImmatriculation_at", null);
        setValue(prefix+"endImmatriculation_at", null);
        setValue(prefix+"modelYear", null);
        //setValue(prefix+"source", null);
        setValue(prefix+"externalID", null);
        setValue(prefix+"bodyType", null);
        setValue(prefix+"drivetrain", null);
        setValue(prefix+"gearbox", null);
        setValue(prefix+"engineDisplacement", null);
        setValue(prefix+"fuelType", null);
        setValue(prefix+"seats", null);
        setValue(prefix+"doors", null);
        setValue(prefix+"environmentalClass", null);
        setValue(prefix+"emissions", null);
        setValue(prefix+"powerHp", null);
        setValue(prefix+"powerKw", null);
        setValue(prefix+"fuelTankCapacity", null);
        setValue(prefix+"fuelCombinedConsumption", null);
        setValue(prefix+"fuelUrbanConsumption", null);
        setValue(prefix+"fuelExtraUrbanConsumption", null);
        setValue(prefix+"electricBatteryCapacity", null);
        setValue(prefix+"electricRangeKm", null);
        setValue(prefix+"electricCombinedConsumption", null);
        setValue(prefix+"electricUrbanConsumption", null);
        setValue(prefix+"electricExtraUrbanConsumption", null);
    }

    const clearLabel = (id) => {
        const el = document.getElementById(id);
        if (typeof el == 'undefined' || !el) return;
        const btn = el.parentNode.parentNode.querySelector('.MuiAutocomplete-clearIndicator');
        if (btn) btn.click();
    }

    return (
        <>
            {
                (!isVolatile) ? (
                    <InputTextControl
                        name={prefix+"make"}
                        label={t("make")}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={true}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                    />
                ) : (
                    <WidgetEditable
                        name={prefix+"make"}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={readonly}
                        options={makeOptions}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                        onChangeLocalAction={getModels}
                    />
                )
            }

            {
                (!isVolatile) ? (
                    <InputTextControl
                        name={prefix+"model"}
                        label={t("model")}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={true}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                    />
                ) : (
                    <WidgetEditable
                        name={prefix+"model"}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={readonly}
                        options={modelOptions}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                        onChangeLocalAction={getSetups}
                    />
                )
            }

            {
                (!isVolatile || source != 'infocar') ? (
                    <InputTextControl
                        name={prefix+"setup"}
                        label={t("setup")}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={(isVolatile && source == 'infocar') ? true : false}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                    />
                ) : (

                    <WidgetEditable
                        name={prefix+"setup"}
                        editing={editing}
                        required={required}
                        disabled={disabled}
                        readonly={readonly}
                        options={setupOptions}
                        className={className}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        hint={hint}
                        haserror={haserror}
                        onChangeLocalAction={getTechnicalData}
                    />
                )
            }
        </>
    );
};


const WidgetEditable = ({ name, editing, required, disabled, readonly, options, onChangeLocalAction, className, placeholder, autoFocus, hint, haserror }) => {
    const { t } = useTranslation();
    const { control, setError, setValue, formState, getValues, resetField } = useFormContext()

    if (!editing) readonly = true;

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState
            }) => {
                let generalClass = "form-element-control form-element-widget-mms form-element-widget-mms-make";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";

                return (
                    <div className={("form-element select " + className).trim()}>
                        <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <FormLabel className="form-element-label" htmlFor={name}>{t(name)}</FormLabel>
                            <Autocomplete
                                id={name}
                                name={name}
                                value={value}
                                error={invalid}
                                onChange={(e, v) => {
                                    onChangeLocalAction(v)
                                    if (v) {
                                        onChange(v.label);
                                    } else {
                                        onChange(v);
                                    }
                                }}
                                variant="outlined"
                                autoComplete="off"
                                className="form-element-field form-element-field-select"

                                autoFocus={autoFocus}
                                placeholder={t(placeholder)}
                                disabled={disabled}
                                readOnly={readonly}
                                required={required}
                                options={options}
                                renderOption={(props, option) => (
                                    <AutocompleteOption {...props}>
                                        <span className="name">{option.label}</span>
                                        <span className="id tag">{option.value}</span>
                                    </AutocompleteOption>
                                )}
                            />

                            <FormHelperText className="form-element-hint">
                                {(haserror || error) ? (<>{error ? error.message : 'Messaggio di errore'}</>) : (<>{hint}</>)}
                            </FormHelperText>
                        </FormControl>
                    </div>
                )
            }}
        />

    )
}

export default WidgetMakeModelSetup;
