import React from "react";
import { List, ListItem, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { VehicleAvatar, LegalEntityAvatar, Tag, PersonAvatar, VehicleInstalledTermometer } from "../Snippet";
import { VehicleKilometer } from "../Chart";
import { Icon } from "../../assets";
import { useDrawer } from "../Drawer";
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Text } from "../Typography";

const InfoHeaderVehicle = ({ view, item }) => {
    const { t } = useTranslation();
    const { activate } = useDrawer();
    const { formatDate, formatDistance } = useFormatter();

    const { loadOne } = useDataProvider('cars/car');
    const handleRefineContract = () => {
        activate('contracts/detail', {
            id: item?.contract?.id,
            defaultValue: item?.contract,
            filters: item?.contract,
        }, () => loadOne(item?.id));
    };

    const handleAssociateContract = () => {
        activate('cars/contractAssociation', {
            id: item?.id,
            defaultValue: item,
            filters: item,
        });
    };

    const handleAssociatePerson = () => {
        activate('cars/personAssociation', {
            id: item?.id,
            defaultValue: item,
            filters: item,
        });
    };

    return (
        <Box className={"info-header info-header-" + view}>
            <VehicleAvatar size="big" url={item?.picture?.url} />
            <Box className={"info-wrapper"}>
                <Box className="info-legal-entity">
                    <LegalEntityAvatar name={item?.legalEntity?.name} url={item?.legalEntity?.logo?.url} size="small" withname={true} />

                    {
                        item?.contract ? (
                            <LegalEntityAvatar name={item?.contract?.renter?.name} url={item?.contract?.renter?.logo?.url} size="small" withname={true} error={(!item?.contract?.start_at || !item?.contract?.end_at) && t("Missing contract's start and/or end date")} onClick={handleRefineContract} />
                        ) : (
                            <LegalEntityAvatar name={item?.contract?.renter?.name} url={item?.contract?.renter?.logo?.url} size="small" withname={true} error={t('No contract assigned')} onClick={handleAssociateContract} />
                        )
                    }

                    {
                        item?.contract?.start_at && (
                            <Text icon="faArrowRightFromLine" className="info-contract">{formatDate(item?.contract?.start_at)}</Text>
                        )
                    }

                    {
                        item?.contract?.end_at && (
                            <Text icon="faArrowRightToLine" className="info-contract">{formatDate(item?.contract?.end_at)}</Text>
                        )
                    }

                    {
                        item?.contract?.contractDuration && (
                            <Text icon="faHourglass" className="info-contract">{item?.contract?.contractDuration} {t('months')}</Text>
                        )
                    }

                    {
                        item?.contract?.contractMileage && (
                            <Text icon="faRoad" className="info-contract">{formatDistance(item?.contract?.contractMileage)}</Text>
                        )
                    }

                </Box>
                <Box className="info-name">
                    <span className="primary">{item?.setup?.make ?? t('Unknown make, model, setup')} {item?.setup?.model}</span><span className="secondary" title={item?.setup?.setup}>{item?.setup?.setup}</span>
                </Box>
                <List className="info-details">
                    <ListItem>
                        <span className="key">{t('plate')}</span>
                        <span className="value">{item?.plate || (<Tag color={"warning"} title={t("No plate yet assigned")}><Icon icon="faWarning" />{t('No plate yet assigned')}</Tag>)}</span>
                    </ListItem>
                    <ListItem>
                        <span className="key">{t('order')}</span>
                        <span className="value">{item?.contract?.orderIdentifier || (<Tag color={"danger"} title={t("Missing contract's order identifier")} onClick={item?.contract ? handleRefineContract : handleAssociateContract}><Icon icon="faWarning" />{t('Missing')}</Tag>)}</span>
                    </ListItem>
                    <ListItem>
                        <span className="key">{t('status')}</span>
                        <Box className="value">
                            {
                                item?.status ? (<Tag color={"secondary"} size="small" label={t(item?.status)} />) : (<Tag color={"danger"} title={t("Missing vehicle status")}><Icon icon="faWarning" />{t('Missing')}</Tag>)
                            }
                        </Box>
                    </ListItem>
                    <ListItem>
                        <span className="key">{t('contractFormula')}</span>
                        <span className="value">{t(item?.contract?.contractFormula) || (<Tag color={"danger"} title={t("Missing contract's formula")} onClick={item?.contract ? handleRefineContract : handleAssociateContract}><Icon icon="faWarning" />{t('Missing')}</Tag>)}</span>
                    </ListItem>
                    <ListItem>
                        <span className="key">
                            {
                                (item?.isPool == "true") ? (
                                    t('poolmembers')
                                ) : (
                                    t('assignee')
                                )
                            }
                        </span>
                        <Box className="value">
                            {
                                Array.isArray(item?.pools) && (item?.pools.length == 1) && (
                                    <PersonAvatar
                                        firstname={item.pools[0].employment?.person?.firstname || item.pools[0].employment?.person?.name}
                                        lastname={item.pools[0].employment?.person?.lastname}
                                        size="small"
                                        url={item.pools[0].employment?.person?.picture?.url}
                                        withname={true}
                                        detail={(item.pools[0].employment?.person?.email ? item.pools[0].employment?.person?.email : '') + " " + (item.pools[0].employment?.person?.mobile ? item.pools[0].employment?.person?.mobile : '')}
                                    />
                                )
                            }

                            {

                                Array.isArray(item?.pools) && (item?.pools?.length > 1) && (
                                    item?.pools?.map((pool, k) => (
                                        <PersonAvatar firstname={pool.employment?.person?.firstname || pool.employment?.person?.name} lastname={pool.employment?.person?.lastname} size="small" url={pool.employment?.person?.picture?.url} />
                                    ))
                                )
                            }

                            {
                                (!Array.isArray(item?.pools)) && (
                                    <Tag color={"danger"} title={t("Missing drivers in pool")} onClick={handleAssociatePerson}><Icon icon="faWarning" />{t('Missing')}</Tag>
                                )
                            }

                        </Box>
                    </ListItem>
                </List>
            </Box>
            {/* 
            contractStartDate={item?.contract?.start_at}<br/>
            contractEndDate={item?.contract?.end_at}<br/>
            contractTotalKM={item?.contract?.contractMileage}<br/>
            lastTraceKMValue={item?.lastTraceKMValue}<br/>
            lastTraceKMDate={item?.lastTraceKMDate}<br/>
            contractCostKMExcess={item?.contract?.bracket1ExtraKmCost}<br/>
            contractMonths={item?.contract?.contractDuration}
             */}
            {
                (item?.contract && item?.contract?.start_at && item?.contract?.end_at) && (
                    <>
                        <VehicleInstalledTermometer
                            item={item}
                            contractStartDate={item?.contract?.start_at}
                            contractEndDate={item?.contract?.end_at}
                            contractMonths={item?.contract?.contractDuration}
                            contractInitialKM={item?.contract?.initialMileage}
                            contractTotalKM={item?.contract?.contractMileage}
                            lastTraceKMValue={item?.traceKmsLast?.value}
                            lastTraceKMDate={item?.traceKmsLast?.traced_at}
                            layout="aside"
                        />
                        <VehicleKilometer
                            item={item}
                            contractStartDate={item?.contract?.start_at}
                            contractEndDate={item?.contract?.end_at}
                            contractMonths={item?.contract?.contractDuration}
                            contractKilometers={item?.contract?.contractMileage}
                            contractCostKMExcess={item?.contract?.bracket1ExtraKmCost}
                            contractKMReimbursement={item?.contract?.bracket1KmReimbursement}
                            contractCostKMCurrency={'EUR'}
                            tracingSerie={item?.traceKms}
                        />
                    </>
                )
            }

        </Box>
    )
}

export default InfoHeaderVehicle;