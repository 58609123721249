import React, { Fragment, useEffect, useState } from 'react'
import { Box, ModalClose } from '@mui/joy'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Cell } from '../ListElements'
import ListToolbar from './ListToolbar'
import { useDataProvider } from '../../../mtska-frontend-data-provider'
import { Text } from '../Typography'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'
import { pick } from 'dot-object'
import { BreadCrumb, useDrawer } from '../Drawer'
import { getCustomFilterOperators, getCustomLocaleText } from './list.functions'

const listId = Math.random().toString(16).slice(2);

const pageSizeOptions = [5, 10, 15, 20, 100];

const List = ({
    title,
    apiRef,
    isDrawer,
    uid,
    handleClose,
    dataProviderName,
    isEmbedded,

    defaultSelectedValues,
    enableSelection,
    disableMultipleRowSelection,
    rowSelectionExternalListener,

    leftButtons = [],
    rightButtons = [],
    hideTableToolbar = false,

    className = "",
    limit = 20,
    filters,
    detailStack,
    dynamicColumns = false,
    onRowSelectionModelChange = () => { },
    onFilterPageSortChange = () => { },
    items,
    total,
    loading,
    onRowDoubleClick = () => { }
}) => {
    const { t } = useTranslation();

    const { item: config } = useDataProvider('configs/config');
    const [tableConfig, setTableConfig] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setTableConfig(config.data.modules[dataProviderName].tableConfig);
    }, [config, dataProviderName])

    const { items: userSetting, save: saveSetting } = useDataProvider('settings/setting');

    const [columns, setColumns] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState();
    const [toolBarLeftButtons, settoolbarLeftButtons] = useState([]);
    // useEffect(()=> {
    //     console.log("leftButtons", leftButtons);
    //     if(!Array.isArray(leftButtons)) return;
    //     // if (JSON.stringify(leftButtons) === JSON.stringify(toolBarLeftButtons)) return;
    //     settoolbarLeftButtons(leftButtons);
    // }, [leftButtons])

    const [toolBarRightButtons, settoolbarRightButtons] = useState([])
    useEffect(() => {
        settoolbarRightButtons(rightButtons);
    }, [rightButtons])


    // FILTERS
    const fmdef = {};
    const [previousFilterModel, setPreviousFilterModel] = useState(fmdef);
    const [filterModel, setFilterModel] = useState(fmdef);
    useEffect(() => {
        setFilterModel(filters);
    }, [filters])

    // SORTING
    const smdef = {};
    const [previousSortModel, setPreviousSortModel] = useState(smdef);
    const [sortModel, setSortModel] = useState(smdef);

    // PAGINATION
    const pmdef = {
        page: 0,
        pageSize: pageSizeOptions.includes(limit) ? limit : pageSizeOptions[0],
    }
    const [previousPaginationModel, setPreviousPaginationModel] = useState(pmdef);
    const [paginationModel, setPaginationModel] = useState(pmdef);

    useEffect(() => {
        let changed = false;
        if (JSON.stringify(filterModel) != JSON.stringify(previousFilterModel)) {
            setPreviousFilterModel(filterModel);
            changed = 'filter';
        }
        if (JSON.stringify(sortModel) != JSON.stringify(previousSortModel)) {
            setPreviousSortModel(sortModel);
            changed = 'sort';
        }
        if (JSON.stringify(paginationModel) != JSON.stringify(previousPaginationModel)) {
            setPreviousPaginationModel(paginationModel);
            changed = 'pagination';
        }

        if (changed) {
            console.info('LANCIO LOADALL DA LISTA INTERNA changed:', changed, 'FilterModel:', filterModel, 'PreviousFilterModel:', previousFilterModel, 'SortModel:', sortModel, 'PaginationModel:', paginationModel)

            onFilterPageSortChange({
                filterModel: filterModel,
                sortModel: sortModel,
                paginationModel: paginationModel,
            })
        }
    }, [paginationModel, filterModel, sortModel])

    // LOAD DATA

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(total || 0);

    const rowCount = React.useMemo(() => {
        if (total !== undefined) {
            rowCountRef.current = total;
        }
        return rowCountRef.current;
    }, [total]);

    // *******************************************************************
    // MANAGE ROW SELECTION & ASSOCIATION
    // *******************************************************************

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const hadleRowSelectionModelChange = (newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
        rowSelectionExternalListener && rowSelectionExternalListener(newRowSelectionModel);
        onRowSelectionModelChange && onRowSelectionModelChange(rowSelectionModel)
    }

    const [viewedItems, setViewedItems] = useState([]);

    // preset rowSelectionModel and viewedItems
    useEffect(() => {
        if (!enableSelection) {
            return;
        }

        if (!Array.isArray(defaultSelectedValues)) {
            setRowSelectionModel([]);
            return;
        }

        const tmp = defaultSelectedValues.reduce((a, v) => ({ ...a, [v.id]: v }), {});
        setViewedItems(tmp);

        const selectedIemsIds = defaultSelectedValues.map(el => el._id);
        setRowSelectionModel(selectedIemsIds);

    }, [defaultSelectedValues, enableSelection]);

    const localHandleSubmit = () => {
        if (!enableSelection) return
        const selectedIems = rowSelectionModel.map(id => viewedItems[id])
        handleClose(uid, selectedIems)
    }

    const handleRowDoubleClick = (row) => {
        onRowDoubleClick(row);
    }

    // add items to viewedItems when age or search change.. to have the full row object available for association
    useEffect(() => {
        if (!Array.isArray(items)) return;
        const tmp = { ...viewedItems, ...items.reduce((a, v) => ({ ...a, [v.id]: v }), {}) };
        setViewedItems(tmp);
    }, [items]);

    useEffect(() => {

        if (dynamicColumns) return;
        if (typeof userSetting[`${dataProviderName}.columnVisibilityModel`] !== 'undefined') {
            setColumnVisibilityModel(userSetting[`${dataProviderName}.columnVisibilityModel`]);
        } else if (typeof tableConfig?.columnVisibilityModel !== 'undefined') {
            setColumnVisibilityModel(tableConfig.columnVisibilityModel);
        }
        if (Array.isArray(tableConfig?.columns)) {
            const columns = tableConfig.columns.map((cc) => {
                const { options } = tableConfig.columnsRendered[cc] || {}
                const filterOperators = [getCustomFilterOperators({ options, t })]
                const customFields = options ? { filterOperators } : {}
                return {
                    flex: 1,
                    field: cc,
                    headerName: t(cc),
                    hideable: tableConfig.columnsRendered[cc]?.hideable === false ? false : true,
                    renderCell: (params) => {
                        const columnRendered = tableConfig.columnsRendered[params?.field]
                        const drawerRoute = tableConfig.columnsRendered[params?.field]?.drawerRoute
                        const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName;
                        const val = typeof params?.row === 'object' ? pick(cc, params.row) : params.value?.value;
                        return (
                            <Fragment>
                                <Cell
                                    type={columnRendered?.type}
                                    columnRendered={columnRendered}
                                    drawerRoute={drawerRoute}
                                    dataProviderName={dataProviderName}
                                    fieldDataProviderName={fieldDataProviderName}
                                    field={params?.field}
                                    value={val}
                                    row={params.row}
                                    filters={filters}
                                    detailStack={detailStack}
                                />
                            </Fragment>
                        )
                    },
                    ...customFields
                }
            })
            setColumns(columns)
        }

    }, [tableConfig, dynamicColumns])

    useEffect(() => {
        if (!dynamicColumns) return;
        if (typeof items[0] === 'undefined') return;

        const cols = Object.getOwnPropertyNames(items[0]).reduce((a, v) => {
            if (v === 'id') return [...a];
            if (v === '_id') return [...a];
            if (v === 'job_id') return [...a];
            if (v === 'checks') return [...a];

            return [...a,
            {
                flex: 1,
                field: v,
                headerName: t(v),
                // cellClassName: (params) => {
                //     const error = params.row?.errors?.[params.field];
                //     return error;
                // },
                renderCell: (params) => {
                    // const columnRendered = tableConfig.columnsRendered[params?.field]
                    // const drawerRoute = tableConfig.columnsRendered[params?.field]?.drawerRoute
                    // const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName;
                    const checks = params.row?.checks?.[params.field] ?? '';
                    const bgColor = checks?.exists === false ? 'red' : (checks?.changes === true) ? 'yellow' : '';
                    const color = checks === '' ? 'lightgray' : 'darkgreen';
                    const val = typeof params?.row === 'object' ? pick(v, params.row) : params.value?.value;
                    return (
                        <Box sx={{ backgroundColor: bgColor, color: { color } }}  >
                            {/* {checks && (<Chip color='warning'>{JSON.stringify(checks)}</Chip>)} */}
                            {val}
                        </Box>
                    )
                }
            }
            ]
        }, [
            // {
            //     field: 'actions',
            //     type: 'actions',
            //     getActions: (params) => [
            //         <GridActionsCellItem label="Import" showInMenu />,
            //         <GridActionsCellItem label="Ignore" showInMenu />,
            //         <GridActionsCellItem label="Delete" showInMenu />
            //     ]
            // }, 
            // {
            //     field: "checks",
            //     type: "string",
            //     valueGetter: (value, row) => {

            //         return JSON.stringify(value);
            //       },
            // }
        ]);
        setColumns(cols);

    }, [items])


    const { toggleFullscreen } = useDrawer();

    return (
        <>
            {isDrawer && (
                <>
                    <Box className="drawer-section-header" >
                        <ModalClose />
                        <Text level="title-md" icon >{title}</Text>
                        <Button label="Save" className="button-main" onClick={localHandleSubmit} />
                    </Box>
                    <BreadCrumb />
                </>
            )}
            <Box className={isEmbedded ? className + " form-element" : "drawer-section-content"}>
                <Box className={(className + " list-system listy-system-" + listId).trim()}>
                    <DataGridPro
                        apiRef={apiRef}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => {
                            setColumnVisibilityModel(newModel);
                            saveSetting({
                                name: `${dataProviderName}.columnVisibilityModel`,
                                value: newModel
                            });
                        }}
                        slots={!hideTableToolbar ? { toolbar: ListToolbar } : {}}
                        slotProps={{
                            toolbar: {
                                leftButtons: leftButtons,
                                rightButtons: rightButtons,
                            }
                        }}
                        disableRowSelectionOnClick
                        checkboxSelection={enableSelection}
                        disableMultipleRowSelection={disableMultipleRowSelection}
                        onRowSelectionModelChange={hadleRowSelectionModelChange}
                        rowSelectionModel={rowSelectionModel}
                        getRowId={(row) => row._id}
                        initialState={{
                            columns: {

                            },
                        }}

                        autoHeight={true}
                        columns={columns}
                        rows={items ?? []}

                        pagination
                        paginationMode="server"
                        rowsPerPage={10}
                        loading={loading}
                        rowCount={rowCount}
                        pageSizeOptions={pageSizeOptions}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        hideFooter={!hideTableToolbar ? false : true}

                        filterMode="server"
                        onFilterModelChange={setFilterModel}

                        sortingMode="server"
                        onSortModelChange={setSortModel}

                        keepNonExistentRowsSelected

                        // onRowClick={handleRowDoubleClick}
                        onRowDoubleClick={handleRowDoubleClick}

                        className={"list-wrapper list-wrapper-" + listId}

                        headerFilters
                        localeText={getCustomLocaleText({ t })}
                    />
                </Box>
            </Box>
        </>
    )
}

export default List



