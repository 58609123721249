import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../mtska-frontend-app-scaffolding';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { Box, Select, Option } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { LegalEntityAvatar } from '../Snippet';
import { Icon } from '../../assets';

const LegalEntitySelector = ({ }) => {
    const { t } = useTranslation();
    const { filterByLegalEntity, setFilterByLegalEntity } = useContext(AppContext);
    const { items: availableLegalEntities, loadAll } = useDataProvider('companies/legalentity');
    const [selectedLegalEntity, setSelectedLegalEntity] = useState();

    useEffect(() => {
        loadAll();
    }, [])

    useEffect(() => {
        setSelectedLegalEntity(availableLegalEntities.find((i) => { return i.id === filterByLegalEntity; }));
    }, []);

    return (
        <Box className="legalentity-menu">
            <Box className="legalentity-icon">
                <LegalEntityAvatar name={(typeof filterByLegalEntity != typeof undefined && filterByLegalEntity) ? filterByLegalEntity?.name : '∞'} url={filterByLegalEntity?.logo?.url} size='squared' />
            </Box>
            <Box className="legalentity-name">
                <span className="label">{t('legalentities')}</span>
                <span className="value">{(typeof filterByLegalEntity != typeof undefined && filterByLegalEntity) ? filterByLegalEntity?.name : t('alllegalentity')}</span>
            </Box>
             {/*
            <Box className="legalentity-list">
                <Select
                    id="input-select-strategy"
                    name="input-select-strategy"
                    value={filterByLegalEntity}
                    onChange={(e, v) => {
                        setFilterByLegalEntity(v);
                    }}
                    slotProps={{
                        listbox: {
                            sx: {
                                maxHeight: '300px',
                                placement: 'bottom-end'
                            },
                        },
                    }}
                    variant="outlined"
                    autoComplete="off"
                    className="form-element-field form-element-field-select"
                >
                    <Option key={0} value={null}>{t('alllegalentity')}</Option>
                    {
                        Array.isArray(availableLegalEntities) && availableLegalEntities.map((legalentity, k) => (
                            <Option key={k} value={legalentity.id}>{legalentity.name}</Option>
                        ))
                    }
                </Select>
                <Icon icon="faChevronRight" className="selector-icon" />
            </Box> */}
        </Box>
    )
}

export default LegalEntitySelector;