import { Create, Default, Detail, List } from "./views";
import { NavigationList } from "../NavigationList"



const Module = {
    name: "roles",
    defaultRoutePath: "/people/role",
    menuItem: {
        ...NavigationList.people.roles
    },

    internalMenuItems: {
        activities: NavigationList.people,
        wizards: []
    },
    routes: [
        {
            path: "/people/role",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
    ],
   
}

export default Module;
