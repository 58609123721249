import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';
const wizardName = 'Status Management';
const wizardObject = 'Status';
const wizardAction = 'statusManagement';

const StatusManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, activeModules } = useContext(AppContext);
    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        if (typeof activeModules === 'undefined') return;
        if (typeof activeModules[dataProviderName] === 'undefined') return;
        //setDetailConfig(activeModules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "management",
                    "fieldsets": [
                        {
                            "name": "management",
                            "fields": {
                                "status": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "in_configuration",
                                            "value": "in_configuration"
                                        },
                                        {
                                            "label": "in_order",
                                            "value": "in_order"
                                        },
                                        {
                                            "label": "running",
                                            "value": "running"
                                        },
                                        {
                                            "label": "closed",
                                            "value": "closed"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-12",
                                    "placeholder": "select",
                                    "icon": "faChartNetwork"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(activeModules[dataProviderName].modelName);
    }, [activeModules, dataProviderName]);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: wizardAction, model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?._id);
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?.id, "status": item?.data?.status }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default StatusManagement;


