//import '../styles/App/App.scss';
import React, { useContext } from "react";
import { Auth0Provider } from '@auth0/auth0-react'
import { Routes } from '../routes';
import { AppContext } from '../contexts';
import { DrawerProvider } from '../../mtska-frontend-app-component';
import { DataProvider } from "src/mtska-frontend-data-provider";

const ContextualizedApp = () => {

    const { auth0, modules } = useContext(AppContext);
    
    return (
        <Auth0Provider
            domain={auth0.domain}
            clientId={auth0.clientId}
            authorizationParams={auth0.authorizationParams}>
            <DataProvider>
                <DrawerProvider modules={modules}  >
                    <Routes modules={modules} />
                </DrawerProvider>
            </DataProvider>
        </Auth0Provider>
    )
}

export default ContextualizedApp;
