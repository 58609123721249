import React from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Services"
                icon="faCard"
                dataProviderName={'statistics/item/count/cars/service'}
            />
            <KPI
                title="Active drivers"
                icon="faPeople"
                dataProviderName={'statistics/item/count/cars/pool'}
                filters={{'items':[{
                    'field':'status',
                    'operator':'equals',
                    'value':'active'
                }]}}
            />
            <KPI
                title="Suppliers"
                icon="faBuilding"
                dataProviderName={'statistics/item/count/companies/legalEntity'}
                filters={{'items':[{
                    'field':'type',
                    'operator':'equals',
                    'value':'supplier'
                }]}}
            />
        </Box>
        <List />
    </>

}

export default Default;


