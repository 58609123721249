import React, { useState, useEffect } from 'react';

const AppContext = React.createContext({
    currentModuleName: 'noname',
    setCurrentModuleName: () => { },
    setCurrentModuleScope: () => { },
    currentModuleNavigationData: [],
    setCurrentModuleNavigationData: () => { },
    filterByLegalEntity: 0,
    userCapabilities:[],
    setUserCapabilities: () => { },
    activeModules:[],
    setActiveModules: () => { },
    setFilterByLegalEntity: () => { },
});

export const AppContextProvider = ({ children, concreteconfig }) => {
    const [currentModuleName, setCurrentModuleName] = useState();
    const [currentModuleScope, setCurrentModuleScope] = useState();
    const [currentModuleNavigationData, setCurrentModuleNavigationData] = useState();
    const [filterByLegalEntity, setFilterByLegalEntity] = useState(localStorage.getItem('filterByLegalEntity'));
    const [loaded, setLoaded] = useState(true);
    const [userCapabilities, setUserCapabilities] = useState([]);
    const [activeModules, setActiveModules] = useState([]);

    const mergeDataProviders = () => {
        const dps = [{
            name: 'default'
        }];
        concreteconfig.modules.map((m) => {
            if (!Array.isArray(m.dataProviders)) return
            return m.dataProviders.map((dp) => {
                return dps.push(dp)
            })
        })
        return dps;
    }

    const config = {
        currentModuleName,
        setCurrentModuleName,
        currentModuleScope,
        setCurrentModuleScope,
        currentModuleNavigationData,
        setCurrentModuleNavigationData,
        filterByLegalEntity,
        setFilterByLegalEntity,
        dataProviders: mergeDataProviders(),
        userCapabilities,
        setUserCapabilities,
        activeModules,
        setActiveModules,
        ...concreteconfig
    }

    return (
        <AppContext.Provider value={config}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;