import { React } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from "./redux/actions";
import { v4 } from "uuid";



export const useDataProvider = (dataProviderName, multipart = false) => {

    const dispatch = useDispatch();
    const dataProviderActions = createAction(dataProviderName, multipart)

    const uid = v4();

   
    const items = useSelector(state => state[dataProviderName])?.items ?? [];
    const total = useSelector(state => state[dataProviderName])?.total ?? 0;
    const loading = useSelector(state => state[dataProviderName])?.loading ?? null;
    const saving = useSelector(state => state[dataProviderName])?.saving ?? null;
    const downloading = useSelector(state => state[dataProviderName])?.downloading ?? null;
    const success = useSelector(state => state[dataProviderName])?.success ?? null;
    const item = useSelector(state => state[dataProviderName])?.item ?? null;
    const errorMessage = useSelector(state => state[dataProviderName])?.errorMessage ?? "";
    const errors = useSelector(state => state[dataProviderName])?.errors ?? {};
   
    const loadAll = (filterModel, sortModel, page, per_page) => {
        dispatch(dataProviderActions.getAll(filterModel, sortModel, page, per_page))
    }

    const reset = () => {
        dispatch(dataProviderActions.reset())
    }

    const loadOne = (id) => {
        return dispatch(dataProviderActions.getOne(id))
    }

    const save = (values) => {
        dispatch(dataProviderActions.save(values))
    }

    const destroy = (id) => {
        return dispatch(dataProviderActions.destroy(id))
    }

    const download = (id) => {
        return dispatch(dataProviderActions.download())
    }

    return {
        // state properties
        uid,
        items,
        total,
        loading,
        saving,
        success,
        item,
        errorMessage,
        errors,

        // functions
        loadAll,
        reset,
        loadOne,
        save,
        destroy,
        download,
        downloading,

    }

};



