import { Default, Detail,  List, Create } from "./views";
import { NavigationList } from "../NavigationList"



const Module = {
    name: "costs",
    defaultRoutePath: "/cars/cost",
    menuItem: {
        ...NavigationList.fleet.costs_and_interventions
    },

    internalMenuItems: {
        activities: NavigationList.fleet,
        wizards: []
    },
    routes: [
        {
            path: "/cars/cost",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],
    
}

export default Module;
