import { AppContextProvider } from '../contexts';
import { ContextualizedApp } from '../app';
import '../../mtska-frontend-app-component/styles/components.scss';


const App = ({ concreteconfig }) => {
    return (
        <AppContextProvider concreteconfig={concreteconfig}>
            <ContextualizedApp />
        </AppContextProvider>
    )
}

export default App;