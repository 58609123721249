import React, { useContext } from 'react';
import { List, ListItem } from '@mui/joy';
import { Anchor } from "../Typography";
import { AppContext } from '../../../mtska-frontend-app-scaffolding';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'src/mtska-frontend-app-component/assets';


const ModuleMenu = ({ activities, wizards }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { currentModuleNavigationData, userCapabilities } = useContext(AppContext);

    return (
        <nav className="module-menu">
            <List className="module-menu-portion module-menu-portion-activities">
                {currentModuleNavigationData?.activities && Object.entries(currentModuleNavigationData?.activities).map(([k, activity]) => {
                    return (((activity && userCapabilities) && userCapabilities[activity.capability] && activity.moduleMenuVisibility) &&
                        <ListItem key={k} className={(activity.href == location.pathname) ? "module-menu-item module-menu-item-selected" : "module-menu-item"}>
                            <Anchor underline="none" className="label" href={activity.href}>
                                {/* {
                                    activity.icon && <Icon icon={activity.icon} />
                                } */}
                                {t(activity.title)}
                            </Anchor>
                        </ListItem>
                    );
                })}
            </List>
        </nav>
    )
};

export default ModuleMenu;
