import React from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const Default = () => {

    const { item } = useDataProvider('auth/token');


    return <>
        <Box className="mtksa-chart-wrapper">

            <KPI
                title="Reports"
                icon="faChartSimple"
                dataProviderName={'statistics/total/countmine/bis/bi'}
            />
        </Box>
        <List />
    </>

}

export default Default;


