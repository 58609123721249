
export const NavigationList = {
    dashboard: {
        "dashboard": {
            scope: "dashboard",
            title: "dashboard",
            href: "/dashboard",
            capability: "dashboard.view",
            icon: "faHome",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
    },
    fleet: {
        "vehicles": {
            scope: "fleet",
            title: "vehicles",
            href: "/cars/car",
            capability: "cars/car.list",
            icon: "faCar",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
        "assignments": {
            scope: "fleet",
            title: "assignments",
            href: "/cars/pool",
            capability: "cars/pool.list",
            icon: "faSteeringWheel",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "setups": {
            scope: "fleet",
            title: "setups",
            href: "/cars/setup",
            capability: "cars/setup.list",
            icon: "faSteeringWheel",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "contracts": {
            scope: "fleet",
            title: "contracts",
            href: "/cars/contract",
            capability: "cars/contract.list",
            icon: "faFileSignature",
            mainMenuVisibility: false,
            moduleMenuVisibility: true,
        },
        "tracekms": {
            scope: "fleet",
            title: "tracekms",
            href: "/cars/tracekm",
            capability: "cars/trace-km.list",
            icon: "faCar",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "costs_and_interventions": {
            scope: "fleet",
            title: "Costs and interventions",
            href: "/cars/cost",
            capability: "cars/cost.list",
            icon: "faSteeringWheel",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "services": {
            scope: "fleet",
            title: "services",
            href: "/cars/service",
            capability: "cars/service.list",
            icon: "faCar",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
    },
    legalentities: {
        "legalentities": {
            scope: "legalentities",
            title: "legalentities",
            href: "/legalentities/legalentity",
            capability: "companies/legalentity.list",
            icon: "faBuilding",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
        "businessunits": {
            scope: "legalentities",
            title: "businessunits",
            href: "/legalentities/businessunit",
            capability: "companies/businessunit.list",
            icon: "faBuilding",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "costcenters": {
            scope: "legalentities",
            title: "costcenters",
            href: "/legalentities/costcenter",
            capability: "companies/costcenter.list",
            icon: "faBuilding",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "profitcenters": {
            scope: "legalentities",
            title: "profitcenters",
            href: "/legalentities/profitcenter",
            capability: "companies/profitcenter.list",
            icon: "faBuilding",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "locations": {
            scope: "legalentities",
            title: "locations",
            href: "/legalentities/location",
            capability: "companies/location.list",
            icon: "faBuilding",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        }
    },


    people: {
        "people": {
            scope: "people",
            title: "people",
            href: "/people/person",
            icon: "faPeople",
            capability: "people/person.list",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
        "employments": {
            scope: "people",
            title: "Employment",
            href: "/people/employment",
            capability: "people/employment.list",
            icon: "faSteeringWheel",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "users": {
            scope: "people",
            title: "users",
            href: "/people/user",
            capability: "auth/user.list",
            icon: "faUser",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "roles": {
            scope: "people",
            title: "roles",
            href: "/people/role",
            capability: "auth/role.list",
            icon: "faUserTag",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
        "permissions": {
            scope: "people",
            title: "Permissions",
            href: "/people/permission",
            capability: "auth/permission.list",
            icon: "faTag",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
    },
    bis: {
        "viewer": {
            scope: "bis",
            title: "Business Intelligence",
            href: "/bis/biviewer",
            capability: "bis/biviewer.list",
            icon: "faGauge",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
        "configuration": {
            scope: "bis",
            title: "BI Configurations",
            href: "/bis/configuration",
            capability: "bis/bi.list",
            icon: "faChartPieSimpleCircleCurrency",
            mainMenuVisibility: false,
            moduleMenuVisibility: true
        },
    },
    documents: {
        "documents": {
            scope: "documents",
            title: "Documents",
            href: "/documents/document",
            capability: "documents/document.list",
            icon: "faFiles",
            mainMenuVisibility: true,
            moduleMenuVisibility: true
        },
    },
}

