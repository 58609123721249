import React, { useState, useEffect } from "react"

import { Box, Chip, CircularProgress } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { useFormatter } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../mtska-frontend-data-provider';

const KPI = ({ title, dataProviderName, icon, filters, format, className = '' }) => {
    const { formatNumber, formatCurrency } = useFormatter();
    const { t } = useTranslation();

    const { items, loadAll, reset } = useDataProvider(dataProviderName);
    const [score, setScore] = useState();
    const [delta, setDelta] = useState();

    useEffect(() => {
        loadAll(filters);
    }, []);

    useEffect(() => {
        setScore(items.kpi);
    }, [items])

    return (
        <Box className={"mtksa-chart mtksa-chart-kpi"+(className && ' '+className)}>
            <Box className="iconwrapper">
                <Icon icon={icon} family="fal"></Icon>
            </Box>
            <Box className="scorewrapper">
                <Text level="title-sm" className="title">{t(title)}</Text>
                <Text level="title-lg" className="score">
                    {
                        (score == null) && <CircularProgress />
                    }

                    {
                        (score != null && !format) && formatNumber(score)
                    }

                    {
                        (score != null && format == 'currency') && formatCurrency(score)
                    }

                </Text>
                {
                    delta && (
                        <Chip
                            className={(delta >= 0) ? "delta positive" : "delta negative"}
                            startDecorator={(delta >= 0) ? <Icon icon="faArrowUp" /> : <Icon icon="faArrowDown" />}
                        >
                            {delta}%
                        </Chip>
                    )
                }
            </Box>
        </Box>
    );
}

export default KPI;