
import React, { useState, useEffect, useContext } from 'react';
import { Detail as DetailComponent, Confirm, useDrawer, SplitButton } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/cost';
const drawerRouteBase = 'costs';

const Detail = (params) => {
    const { t } = useTranslation();
    const { activate, close: closeDrawer } = useDrawer();
    const { userCapabilities, activeModules } = useContext(AppContext);

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (activeModules === undefined) return;
        if (typeof activeModules[dataProviderName] === 'undefined') return;
        setDetailConfig(activeModules[dataProviderName].detailConfig);
        setModelName(activeModules[dataProviderName].modelName);
    }, [activeModules, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.id)) {
            loadOne(params.id);
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    //await params.handleClose(params.uid, item.data);
                    await params.handleClose(params.uid)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = (id) => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={() => {
                    destroy && destroy(id);
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Item elimination")}>
                {t("Are you sure to remove this element?")}
            </Confirm>
        );
    }

    return (
        <>
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName+'.edit']}
                title={item?.data.valueTotal ? item?.data.valueTotal + ' ' + item?.data.valueCurrency : t('New cost')}
                subtitle={item?.data.invoiceID ? t('Invoice') + ' ' + item?.data.invoiceID : (item?.data.invoiceDescription && item?.data.invoiceDescription)}
                rightButtons={[
                    <SplitButton 
                        actions={[
                            {
                                disabled:userCapabilities && !userCapabilities[dataProviderName+'.delete'],
                                icon:"faTrash",
                                label:t('Delete'),
                                onClick:() => { handleDelete(item.data.id); }
                            }
                        ]}
                    />
                ]}
                {...params}
            />

            {confirmDelete}
        </>
    )
}

export default Detail;


