import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/contract';
const drawerRouteBase = 'contracts';
const wizardName = 'Service association';
const wizardObject = 'Service';
const wizardAction = 'serviceAssociation';

const ServiceAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, activeModules } = useContext(AppContext);
    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        if (typeof activeModules === 'undefined') return;
        if (typeof activeModules[dataProviderName] === 'undefined') return;
        //setDetailConfig(activeModules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "contract_service_association",
                    "fieldsets": [
                        {
                            "name": "contract_service_association",
                            "fields": {
                                "available_services": {
                                    "type": "sectiontitle",
                                    "className":"col-12"
                                },
                                "associated": {
                                    "type": "servicelist",
                                    "defaultSelectedValues":[], 
                                    "enableSelection":true, 
                                    "disableMultipleRowSelection":true,
                                    "dataProviderName":"cars/service",
                                    "filters":{
                                        "items":[
                                            {
                                                "field":"employment_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            },
                                            {
                                                "field":"car_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            },
                                            {
                                                "field":"contract_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            }
                                        ]
                                    },
                                    "className":"col-12",
                                    "required": "true"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(activeModules[dataProviderName].modelName);
    }, [activeModules, dataProviderName]);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: wizardAction, model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?._id);
        close();
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{identifier}}', { wizardObject:wizardObject, identifier: item.data.contractIdentifier })} 
                />
            }
            {...params}
        />
    </>
}

export default ServiceAssociation;


