import React from "react"
import { DrawerButton } from "../../../../mtska-frontend-app-component";
import { useTranslation } from 'react-i18next';

const ImportButton = ({ dataProviderName, moduleName, callback, disabled }) => {
    const { t } = useTranslation();

    return (
        <DrawerButton
            drawerRoute={'imports/list'}
            params={{
                filters: {
                    items: [{
                        operator: "equals",
                        field: "model",
                        value: dataProviderName,
                    }]
                },
                currentDataProviderName: dataProviderName,
                moduleName: moduleName,
            }}
            callback={callback}
            label={t("Import")}
            disabled={disabled}
        />
    );
};


export default ImportButton;
