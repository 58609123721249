import * as Views from "./views";
import { NavigationList } from "../NavigationList"


const Module = {
    name: "biviewer",
    defaultRoutePath: "/bis/biviewer",
    menuItem: {
        ...NavigationList.bis.viewer
    },

    internalMenuItems: {
        activities: NavigationList.bis,
        wizards: []
    },
    routes: [
        {
            path: "/bis/biviewer",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
    ]
}



export default Module;
